.MuiInputBase-input {
    box-sizing: border-box !important;
    border-radius: 2px !important;
    flex-shrink: 0 !important;  
}

.delete-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 100; /* Ensure the modal is above the grid */
  display: flex;
  justify-content: center;
  align-items: center;
}

button.rec-dot{
 visibility: hidden;
}
.customTextField{
  color: #010101 !important;
}
.customTextField::placeholder{
  color: hsl(0,0%,50%) !important;
  opacity: 1 !important;
}

.phoneInputInputClass::placeholder{
  color: hsl(0,0%,50%) !important;
  opacity: 1 !important;
}
.phoneInputInputClass{
  width: 100% !important;
  height: 48px !important;
  border-left: none;
  font-size:16px !important;
  color: #010101 !important;
}
.phoneInputDropdownClass{
  top: 43px;
  text-align: left;
  border-radius:4px !important;
  box-shadow: none !important;
  border: 1px solid #ddd;
  width: 240px !important;
}
.carousel-container {
  position: relative;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}
.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 28px;
  }
  .switch input {   
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 15px;
    bottom: 0;
    background-color: #00FFA7;
    -webkit-transition: .4s;
    transition: .4s;
    width: 48px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 3px;
    bottom: 3px;
    background-color: #FFF;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked+.slider {
    background-color: #00FFA7;
  }
  input:focus+.slider {
    box-shadow: 0 0 1px #FFF;
  }
  input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  .imageHover:hover{
      opacity: 0.7;
      filter:grayscale(0.3);
      transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out; 
  }
  .imageIcons{
    position: absolute;
    top: 92%;
    left: 15%;
    transform: translate(-50%, -50%);
    display: flex;
}
#myIcon {
  fill: 'black'
}
  .slider.round {
    border-radius: 50px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  @media screen and (max-width: 768px) {
    .img-div {
      position: relative;
      z-index: 99;
    }
  }
  .profile-img {
    max-width: 189px;
    align-self: center;
  }
  @media (max-width: 800px) {
    .img-div {
      max-width: fit-content;
      flex-direction: column;
    }
   
    @media screen and (max-width: 768px) {
      .img-div {
        max-width: none;
      }
    }
    .userName-div {
      margin: auto;
      align-self: center;
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
    @media screen and (max-width: 768px) {
      .userName-div {
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .userDescMobileDiv {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
      }
      .descTextMobile {
        text-align: center !important;
      }
      .borderBtmMob {
        display: none !important;
      }
  }
  
  @media screen and (max-width: 768px) {
    .profileImg {
      margin-top: 25px !important;
      width: 100px !important;
      height: 100px !important;
    }
}
 
  @media screen and (max-width: 768px) {
    .userName-cta {
      flex-direction: row !important;
      align-items: center !important;
      gap: 12px !important;
    }
    .userName-cta button {
      padding: 2px 12px !important;
      margin-bottom: 0 !important;
    }
}
.usercarouselImg {
  height: 250px;
  width: 365px;
}
@media screen and (max-width: 768px) {
  .usercarouselImg {
    height: 125px;
    width: 150px;
  }
}
.user-carousel {
  @media screen and (max-width: 768px) {
    margin-top: 0 !important;
    width: 100% !important;
    height: 150px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }
}
    .workInfo-div{
      margin-top: 310px !important;
    }
    .user-descediv {
      flex-shrink: 1;
      display: grid;
    }
  }
  .datepicker {
    height: 40px;
   }
   
  .date-picker-container {
    position: relative;
    display: inline-block;
  }
  .sendBtn{
    background-color: 'gray';
  }
  .calendar-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .colorButton{
    background: linear-gradient(to right, gray 0%, black 100%);
    background-size: 200%; /* This sets the animation size */
    animation: black-fill 60s linear forwards; /* Defines the animation */
  }
  @keyframes black-fill {
    from {
      background-position: 0 0; /* Starts from the left */
    }
    to {
      background-position: -100% 0; /* Moves to the right to cover the button */
    }
  }
 
  @media screen and (max-width: 768px) {
    .rec-carousel button {
      display: none !important;
    }
  }
  @media screen and (max-width: 768px) {
    .table-description-wrapper {
      display: none;
    }
  }
  .table-mobile-wrapper {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .table-mobile-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .locationOuterDivTalent {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      gap: 10px;
      margin-left: 0px !important;
      width: 100% !important;
      padding: 0 4px 0 10px !important;
      margin-top: 25px !important;
    }
    .shareLikBtnMobile {
      display: none !important;
    }
    .typeMobileDiv {
      display: flex !important;
    }
    .outerMostMobileSizeTalent {
      height: 550px !important;
    }
  }
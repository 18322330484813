.user-carousel {
    margin-top: 130px;
    margin-left: 100px;
    margin-right: 100px;
  }
  .user-profile-img{
    max-width: 189px;
  }
  .shareSavBox {
    padding-right: 18px;
  }
  .descNameMob {
    padding-top: 8px;
  }
  @media (max-width: 768px) {
      .boxContainerMob {
          border: none !important;
          border-bottom: 1px solid #DFDFDF !important;
          margin-top: 0!important;
      }
      .profileDivMob {
          gap: 10px !important;
      }
 
      .postImgDivMob {
          padding: 20px 0 10px !important;
      }
 
      .postImgMob {
          height: 300px !important;
          object-fit: cover !important;
      }
 
      .shareBtnDivMob {
          padding: 0px 20px !important;
      }
 
      .descBoxMob {
          padding: 30px 30px !important;
          word-wrap: break-word !important;
      }
 
      .tagMob {
          display: grid !important;
          gap: 20px !important;
          grid-template-columns: repeat(2, auto) !important;
          margin: 0 !important;
          padding: 0 10px !important;
      }
 
      .tagHeaderMob {
          margin: 20px 12px !important;
      }
 
      .collabDivMob {
          margin: 10px !important;
          flex-direction: column !important;
          gap: 0 !important;
      }
      .headlineMob {
        padding-top: 0 !important;
      }
      .collabBoxMob {
        position: relative;
          display: flex !important;
          border: none !important;
          border-bottom: 1px solid #DFDFDF !important;
          height: auto !important;
          width: auto !important;
          padding: 0 !important;
          gap: 18px !important;
      }
      .collabBtmBorderMob {
          display: none !important;
      }
      .collabProfileImgMob {
          width: 48px !important;
          height: 48px !important;
      }
      .descriptionMob {
          font-size: 11px !important;
          line-height: 12px !important;
      }
      .tagsContainerMob {
          border: 1px solid #DFDFDF !important;
          margin: 10px !important;
          padding-bottom: 10px !important;
          border-radius: 4px !important;
      }
      .tagCardMob {
          width: 100px !important;
      }
      .outerMostMobileSize {
          height: 400px !important;
          width: auto !important;
          border: 1px solid #DFDFDF !important;
          margin: 10px !important;
          padding-bottom: 10px !important;
          border-radius: 4px !important;
        }
        .imgDivMob {
          max-width: none !important;
          position: relative;
          z-index: 99;
          display: block !important;
          padding: 15px !important;
        }
        .user-profile-img{
          width: 100% !important;
          max-width: 100% !important;
          display: flex;
          justify-content: center;
          img {
            margin-top: 0 !important;
          }
        }
        .profileImgMob {
          margin-top: 25px !important;
          width: 100px !important;
          height: 100px !important;
          text-align: center !important;
          max-width: none !important;
        }
        .userNameMob {
          justify-content: center;
          flex-direction: column;
          align-items: center;
          font-size: 14px !important;
          line-height: 12px !important;
          margin-top: 18px !important;
        }
        .descNameMob {
          display: flex !important;
          flex-direction: column !important;
          margin-top: 25px !important;
          padding-right: 25px !important;
        }
        .descTextMobile {
          text-align: center !important;
        }
        .mobDescNameMob {
          display: flex !important;
          flex-direction: column !important;
          text-align: center !important;
        }
        .shareLikBtnMobile {
          display: none !important;
        }
        .table-description-wrapper {
          display: none !important;
        }
        .table-mobile-wrapper {
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          gap: 10px !important;
        }
        .locationOuterDiv {
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          gap: 10px;
          margin-left: 0px !important;
          width: 100% !important;
          margin-right: 60px!important;
        }
        .typeMobileDiv {
          display: flex !important;
          width: 50%;
        }
        .user-carousel {
          padding: 4px !important;
          margin-top: 0 !important;
          width: 100% !important;
          height: 150px !important;
          position: absolute !important;
          margin-left: 0 !important;
          top: 0 !important;
          left: 0 !important;
          div {
            flex-wrap: unset !important;
          }
        }
        .usercarouselImg {
          height: 100px !important;
          width: 100% !important;
        }
        .rightAlignOptionMob {
          bottom: 20px !important;
        }
        .deleteIconMob {
          position: absolute;
          right: 5px;
          top: 50px;
        }
        .openEyeMob {
          position: absolute !important;
          right: -35px !important;
          top: 10px !important;
        }
        .closeEyeMob{
          position: absolute !important;
          right: -10px !important;
          top: 10px !important;
        }
        .openEyeMobs {
          position: absolute !important;
          right: -35px !important;
          top: 10px !important;
        }
        .closeEyeMobs {
          position: absolute !important;
          right: -10px !important;
          top: 10px !important;
        }
      }
     